/* eslint-disable import/no-unused-modules */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components/common';
import SEO from '../../components/common/SEO';
import ogimage from '../../images/events/2023/avs/AVS-2023-og-image.jpg';
import { useViewport } from '../../hooks';
import DesktopEventPage from '../../components/common/DesktopEventPage';
import MobileEventPage from '../../components/common/MobileEventPage';
import banner from '../../images/events/2023/avs/AVS-2023-desktop-hero.jpg';
import mobileBanner from '../../images/events/2023/avs/AVS-2023-mobile-hero.jpg';

export default function AsiaVideoSummit() {
    const { t } = useTranslation();

    const { width } = useViewport();
    const breakpoint = 500;

    return (

        <Layout title='Asia Video Summit 2023 - Witbe' isEvents>
            <SEO
                title='Witbe at Asia Video Summit 2023: Automated Testing & Monitoring'
                ogDescription='Join us at Asia Video Summit 2023 to explore Witbe’s automated testing and monitoring tech. Discover our Android TV OS testing and Witbe Cloud data center.'
                description='Join us at Asia Video Summit 2023 to explore Witbe’s automated testing and monitoring tech. Discover our Android TV OS testing and Witbe Cloud data center.'
                article='article'
                image={ogimage}
            />

            {width < breakpoint ? <MobileEventPage id={3} banner={mobileBanner} /> : <DesktopEventPage id={3} banner={banner} />}
        </Layout>
    );
}
